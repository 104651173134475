import axios from "axios";
import localStorageService from "./localStorageService";
import customConstants from "../../Constants";
import queryString from 'query-string';

let user = {};
let hasToken = false;
let _this;

class JwtAuthService {

    constructor() {
        _this = this;
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            try {
                if (error.response && error.response.status === 401 && !(customConstants.availableRoutes.includes(window.location.pathname))) {
                    const hasToken = localStorage.getItem('jwt_token');
                    _this.setSession(null);
                    _this.removeUser();
                    if (customConstants.SITE_LOGIN === false) {
                        window.location = customConstants.LOGIN_URL
                    } else {
                        if (hasToken !== null) {
                            window.location = '/session/sign-in?timeout=true';
                        } else {
                            window.location = '/session/sign-in';
                        }
                    }

                }
            } catch (e) {
                console.log(e);
            }

            return error;
        });

    }

    loginWithEmailAndPassword = (email, password) => {

        return new Promise((resolve, reject) => {
            localStorage.clear();
            const form = new FormData();
            form.append('client_id', email);
            form.append('client_secret', password);
            form.append('grant_type', 'client_credentials');

            return axios(
                {
                    url: customConstants.API_URL + '/oauth/access_token',
                    method: 'POST',
                    headers: {
                        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                    },
                    data: form
                }
            ).then(function (response) {

                localStorage.setItem('completedCalls', 0);


                if (typeof response.data?.access_token !== 'undefined') {

                    if (response.data.access_token) {
                        localStorage.setItem('client_id', email);
                        localStorage.setItem('jwt_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        localStorage.setItem('access_token_expires', +new Date() + response.data.expires_in * 1000);

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

                        var config = {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                            },
                            crossdomain: true
                        };

                        setTimeout(function () {
                            return axios.get(customConstants.API_URL + '/user/grants/WEB', config
                            ).then(function (response) {


                                _this.setUser(response.data);


                                if (typeof response.data === 'undefined') {
                                    reject();
                                } else {

                                    let grants = [];
                                    response.data.map(function (v, k) {
                                        grants.push(v.serviceName)
                                    });

                                    localStorage.setItem('grants', JSON.stringify(grants));

                                    try {
                                        return axios.get(customConstants.API_URL + '/user/me').then(res => {

                                            user = {
                                                userId: res.data.organisationId,
                                                role: 'ADMIN',
                                                roles: res.data.roles,
                                                displayName: res.data.username,
                                                email: res.data.username,
                                                photoURL: "/assets/images/face-7.jpg",
                                                age: 26,
                                                token: localStorage.getItem('jwt_token')
                                            }

                                            if (res.data?.additionalUserData) {
                                                localStorage.setItem('assistboxId', res.data.additionalUserData?.assistboxId);
                                                localStorage.setItem('queueCode', res.data.additionalUserData?.assistboxQueueCode);
                                            }

                                            _this.setUser(user);

                                            setTimeout(function () {
                                                window.location = '/all';
                                            }, 1000);

                                            resolve();

                                        }).catch(function (e) {

                                            if (!(customConstants.availableRoutes.includes(window.location.pathname))) {
                                                const hasToken = localStorage.getItem('jwt_token');
                                                this.setSession(null);
                                                this.removeUser();
                                                if (customConstants.SITE_LOGIN === false) {
                                                    window.location = customConstants.LOGIN_URL
                                                } else {
                                                    if (hasToken !== null) {
                                                        window.location = '/session/sign-in?timeout=true';
                                                    } else {
                                                        window.location = '/session/sign-in';
                                                    }

                                                }

                                            }
                                        });
                                    } catch (e) {
                                        console.error(e)
                                    }


                                }

                            }).catch(function (error) {
                                reject(response);
                            });
                        }, 500)
                    } else if (typeof response.data.mfa_type !== 'undefined') {
                        if (response.data.mfa_type === "SMS") {
                            localStorage.setItem('username', email);
                            localStorage.setItem('secret', password);
                            localStorage.setItem('mfa_end', Date.now() + customConstants.OTP_TIMEOUT * 1000);
                            window.location = '/session/mfa';
                        } else {
                            reject(response)
                        }
                    } else {
                        reject(response)
                    }

                } else {
                    reject(response);
                }

            }).catch(function (error) {
                reject(error);
                return false;
            });

        })
    };

    loginWithToken = () => {

        let query = queryString.parse(window.location.search);
        let atoken = '';
        let _this = this;

        return new Promise((resolve, reject) => {
            if (query.token && typeof query.token !== 'undefined' && query.token !== '') {
                localStorage.setItem('jwt_token', decodeURIComponent(query.token.toString()));
                atoken = decodeURIComponent(query.token.toString());
                hasToken = true;
                localStorage.setItem('jwt_token', atoken);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');

                var config = {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                    },
                    crossdomain: true
                };

                return axios.get(customConstants.API_URL + '/user/grants/WEB', config
                ).then(function (response) {

                    if (typeof response.data === 'undefined') {

                    } else {

                        try {

                            let grants = [];
                            response.data.map(function (v, k) {
                                grants.push(v.serviceName)
                            });

                            localStorage.setItem('grants', JSON.stringify(grants));
                            return axios.get(customConstants.API_URL + '/user/me').then(res => {

                                user = {
                                    userId: res.data.organisationId,
                                    role: "ADMIN",
                                    roles: res.data.roles,
                                    displayName: res.data.username,
                                    email: res.data.username,
                                    photoURL: "/assets/images/face-7.jpg",
                                    age: 26,
                                    token: localStorage.getItem('jwt_token')
                                }

                                if (res.data.additionalUserData) {
                                    localStorage.setItem('assistboxId', res.data.additionalUserData.assistboxId);
                                }

                                _this.setUser(user);

                                window.location.href = window.redirect_uri

                            }).catch(function (e) {
                                if (!(customConstants.availableRoutes.includes(window.location.pathname))) {
                                    const hasToken = localStorage.getItem('jwt_token');
                                    _this.setSession(null);
                                    _this.removeUser();

                                    if (customConstants.SITE_LOGIN === false) {
                                        window.location = customConstants.LOGIN_URL
                                    } else {

                                        if (hasToken !== null) {
                                            window.location = '/session/sign-in?timeout=true';
                                        } else {
                                            window.location = '/session/sign-in';
                                        }
                                    }

                                }
                            });

                        } catch (e) {
                            console.error(e)
                        }

                    }

                }).catch(function (error) {
                });
            } else {


                if (!(['/web-sdk/demo', '/web-sdk/colendi'].includes(window.location.pathname))) {
                    if (!localStorage.getItem("grants")) {
                        localStorage.setItem("grants", JSON.stringify(["verifications"]))
                    }

                    try {
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt_token');
                        return axios.get(customConstants.API_URL + '/user/me').then(res => {

                            user = {
                                userId: res.data.organisationId,
                                role: 'ADMIN',
                                roles: res.data.roles,
                                displayName: res.data.username,
                                email: res.data.username,
                                photoURL: "/assets/images/face-7.jpg",
                                age: 26,
                                token: localStorage.getItem('jwt_token')
                            }

                            if (res.data.additionalUserData) {
                                localStorage.setItem('assistboxId', res.data.additionalUserData.assistboxId);
                            }

                            _this.setUser(user);

                            if (localStorage.getItem('grants') === null) {
                                _this.setSession(null);
                                _this.removeUser();
                                localStorage.removeItem('jwt_token');
                            }


                        }).catch(function (e) {
                            if (!(customConstants.availableRoutes.includes(window.location.pathname))) {
                                const hasToken = localStorage.getItem('jwt_token');
                                _this.setSession(null);
                                _this.removeUser();
                                console.log(e);
                                if (customConstants.SITE_LOGIN === false) {
                                    window.location = customConstants.LOGIN_URL
                                } else {
                                    if (hasToken !== null) {
                                        window.location = '/session/sign-in?timeout=true';
                                    } else {
                                        window.location = '/session/sign-in';
                                    }
                                }

                            }
                        });
                    } catch (e) {
                        console.error(e)
                    }
                }
            }
        });

    };

    logout = () => {
        this.setSession(null);
        this.removeUser();
        sessionStorage.removeItem("status")
    }

    setSession = token => {

    };
    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
    }
    removeUser = () => {
        localStorage.removeItem("auth_user");
        localStorage.removeItem("_atoken");
        localStorage.removeItem("_rtoken");
        localStorage.removeItem("grants");
        localStorage.removeItem("jwt_token");
        window.redirect_uri = '/';

        if (customConstants.SITE_LOGIN === false) {
            window.location = customConstants.LOGIN_URL
        } else {
            window.location = '/session/sign-in';
        }

    }
}

export default new JwtAuthService();

