import "../fake-db";
import "../styles/_app.scss";
import React from "react";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";

import routes from "./RootRoutes";
import {Store} from "./redux/Store";
import Auth from "./auth/Auth";
import EgretLayout from "./EgretLayout/EgretLayout";
import AuthGuard from "./auth/AuthGuard";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import customConstants from "Constants";

if (customConstants.INCLUDE_ASSISTBOX_SDK) {
    const script_queue = document.createElement("script");
    script_queue.src = "/asistbox-queue-sdk.mjs";
    script_queue.async = true;
    document.body.appendChild(script_queue);
    const script_web_sdk = document.createElement("script");
    script_web_sdk.src = "/assistbox-web-sdk.mjs";
    script_web_sdk.async = true;
    document.body.appendChild(script_web_sdk);
}

const App = () => {

    return (
        <AppContext.Provider value={{routes}}>
            <Provider store={Store}>
                <EgretTheme>
                    <Auth>
                        <Router history={history}>
                            <AuthGuard>
                                <EgretLayout/>
                            </AuthGuard>
                        </Router>
                    </Auth>
                </EgretTheme>
            </Provider>
        </AppContext.Provider>
    );
};

export default App;
