import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    InputAdornment,
    TextField
} from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase";
import {withStyles} from "@material-ui/styles";
import jwtAuthService from "../../../app/services/jwtAuthService";
import i18n from "i18next";
import {ExpandMore, FileCopy, Link, Undo} from "@material-ui/icons";
import axios from "axios";
import customConstants from "../../../Constants";
import {withSnackbar} from "material-ui-snackbar-provider";
import {setDefaultSettings, setLayoutSettings} from "app/redux/actions/LayoutActions";
import {withRouter} from "react-router-dom/cjs/react-router-dom";
import {connect} from "react-redux";
import {navLinks} from "app/navigations";

const useHasRoles = (rolenames) => {
    const userRole = useUserRole();
    if (userRole) {
        for (let i = 0; i < userRole.length; ++i) {
            if (userRole[i] === rolenames)
                return true;
        }
        return false;
    } else
        return false;
}

const useUserRole = () => {
    try {
        const auth_user = localStorage.getItem('auth_user');
        return JSON.parse(auth_user)['roles'];
    } catch (e) {

    }
}

let _this = this;

class EgretVerticalNav extends Component {
    state = {
        collapsed: true,
        showBackToHome: false,
        copyLinkModal: false,
    };

    componentDidMount() {
        const slugs = window.location.pathname.split('/').slice(1);
        this.setState({showBackToHome: slugs.includes('web-sdk')});
    }

    handleSignOut = (e) => {
        e.preventDefault()
        jwtAuthService.logout();
    };

    closeWindow = (e) => {
        e.preventDefault()
        //window.close();
        setTimeout(function () {
            window.location = '/';
        }, 250)
    };

    copyItem = () => {
        navigator.clipboard.writeText(this.state.link);
        this.props.snackbar.showMessage(i18n.t('Copied'), 'success');
    }

    createDemoLink() {

        this.props.snackbar.showMessage(i18n.t('Creating Link...'), 'warning');

        axios.post(customConstants.API_URL + '/id/start', {}).then(res => {

            const gestureType = res.data.gestureType;
            const transactionId = res.data.transactionId;

            const newLink = customConstants.BASE_URL + '/techsign-web-card-detection/?token=' + encodeURIComponent(localStorage.getItem('jwt_token')) + '&lg=tr&transactionId=' + transactionId + '&gestureType=' + gestureType;

            axios.post('https://api-ssl.bitly.com/v4/shorten', {
                "domain": "bit.ly",
                "long_url": newLink
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer e23c150ae7846a4ac9303a020904311b4205f483'
                }
            }).then(res => {
                this.props.snackbar.showMessage(i18n.t('Link Created'), 'success');
                const shortenLink = res.data.link;
                this.setState({link: shortenLink, copyLinkModal: true});
            });

        });

    }

    handleCloseCopyModal = () => {
        this.setState({copyLinkModal: false})
    }

    hasGrantInSection = (sections) => {
        let show = false
        sections.forEach(section => {
            show = show || section.auth
        })
        return show
    }

    hasGrantInNestedSection = (sections) => {
        let show = false
        sections.forEach(section => {
            if (section.sections) {
                section.sections.forEach(_section => {
                    show = show || _section.auth
                })
            } else {
                show = show || section.auth
            }
        })
        return show
    }

    renderLevels = data => {
        const hasMakerRole = useHasRoles("maker");
        const hasCheckerRole = useHasRoles("checker");
        const hasAgentRole = useHasRoles("agent");
        const hasAgentLeadRole = useHasRoles("agent_lead");
        const hasAdminRole = useHasRoles("admin");

        return <>
            <Dialog
                width={"lg"}
                open={this.state.copyLinkModal}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogContent>
                    <DialogContentText>

                        <TextField
                            onClick={() => this.copyItem()}
                            value={this.state.link}
                            id="standard-basic"
                            label={i18n.t('Shorten Link')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FileCopy style={{cursor: "pointer"}}/>
                                    </InputAdornment>
                                )
                            }}
                        />

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCloseCopyModal}
                            style={{backgroundColor: "#999", color: "#fff"}}>
                        {i18n.t('Cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
            {!this.state.showBackToHome ?
                <>
                    {
                        navLinks.map(nav => {
                            if (nav.auth && this.hasGrantInNestedSection(nav.sections)) {
                                return <>
                                    <div className="nav-title">
                                        <nav.icon className="item-icon text-middle"/>
                                        <h4>{nav.name}</h4>
                                    </div>
                                    {
                                        nav.sections.map(section => {
                                            if (section.auth && (section.sections ? this.hasGrantInSection(section.sections) : true)) {
                                                if (section?.sections) {
                                                    return <Accordion>
                                                        <AccordionSummary expandIcon={<ExpandMore/>}>
                                                            <span className="item-text">{section.name}</span>
                                                        </AccordionSummary>
                                                        {
                                                            section?.sections?.map(_section => {
                                                                if (_section?.auth) {
                                                                    return <NavLink to={_section.path}
                                                                                    className="nav-item"
                                                                                    onClick={this.handleClick}>
                                                                        <TouchRipple name="child" className="w-100">
                                                                            <span
                                                                                className="item-text">{_section.name}</span>
                                                                            <div className="mx-auto"></div>
                                                                        </TouchRipple>
                                                                    </NavLink>
                                                                }
                                                            })
                                                        }
                                                    </Accordion>
                                                } else {
                                                    return <NavLink to={section.path} className="nav-item"
                                                                    onClick={this.handleClick}>
                                                        <TouchRipple name="child" className="w-100">
                                                            <span className="item-text">{section.name}</span>
                                                            <div className="mx-auto"></div>
                                                        </TouchRipple>
                                                    </NavLink>
                                                }
                                            }
                                        })
                                    }
                                </>
                            }
                        })
                    }

                    {(JSON.parse(localStorage.getItem('grants')) || []).includes("demo") && <>
                        <hr style={{margin: 15}}/>

                        <div className="nav-title">
                            <Link className="item-icon text-middle"/>
                            <h4>{i18n.t('Demo')}</h4>
                        </div>
                        <NavLink to={'#'} className="nav-item"
                                 onClick={() => window.open('/web-sdk-demo/index.html?token=' + encodeURIComponent(localStorage.getItem('jwt_token')))}>
                            <TouchRipple name="child" className="w-100">
                                <span className="item-text">{i18n.t('Go to Web SDK Demo')}</span>
                                <div className="mx-auto"></div>
                            </TouchRipple>
                        </NavLink>
                        <NavLink to={"/link"} className="nav-item" onClick={() => this.createDemoLink()}>
                            <TouchRipple name="child" className="w-100">
                                <span className="item-text">{i18n.t('Create Demo Link')}</span>
                                <div className="mx-auto"></div>
                            </TouchRipple>
                        </NavLink>
                    </>}
                </>
                :
                <NavLink to={'#'} className="nav-item" onClick={(e) => this.closeWindow(e)}>
                    <TouchRipple name="child" className="w-100">
                        <Undo className="item-icon text-middle"/>
                        <span className="text-middle pl-20 item-text">{i18n.t('Go Back To Dashboard')}</span>
                        <div className="mx-auto"></div>
                    </TouchRipple>
                </NavLink>}
        </>
    };

    handleClick = () => {
        // this.setState({collapsed: !this.state.collapsed});
        const {settings, setLayoutSettings} = this.props;
        setLayoutSettings({
            ...settings,
            layout2Settings: {
                ...settings.layout2Settings,
                leftSidebar: {
                    ...settings.layout2Settings.leftSidebar,
                    mode: "close"
                }
            }
        })
    };

    render() {
        return (
            <div className="navigation">
                {this.renderLevels(this.props.navigation)}
            </div>
        );
    }
}

EgretVerticalNav.propTypes = {
    setDefaultSettings: PropTypes.func.isRequired,
    setLayoutSettings: PropTypes.func.isRequired,
    settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    setDefaultSettings: PropTypes.func.isRequired,
    setLayoutSettings: PropTypes.func.isRequired,
    settings: state.layout.settings
});

export default withStyles({}, {withTheme: true})(
    withRouter(
        connect(
            mapStateToProps,
            {
                setLayoutSettings,
                setDefaultSettings
            }
        )(withSnackbar()(EgretVerticalNav))
    )
);
